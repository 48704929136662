<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('Office:')"
        prop="office"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-select
          v-model="form.office"
          placeholder="Select Office"
          :disabled="data != null"
          class="w-100"
          @change="getClientsAndUsers"
        >
          <el-option
            v-for="item in offices"
            :key="item.id"
            :label="item.office_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="'Client'"
        prop="corporate"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Client"
          @input="getBankAccount"
          :options="clients"
          label="name"
          :reduce="(corporate) => corporate.uuid"
          v-model="form.corporate"
          :disabled="data != null"
        ></v-select>
      </el-form-item>

      <el-form-item
        :label="'Bank - Bank Accnt:'"
        prop="bank"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Bank - Bank Accnt:"
          @input="setBankAccount(null)"
          :options="bankAccounts"
          label="name"
          :reduce="(bankAccount) => bankAccount"
          v-model="bankAccount"
        ></v-select>
      </el-form-item>

      <el-form-item :label="$t('Start Date')" prop="startdate">
        <el-date-picker
          type="date"
          v-model="form.startdate"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
          class="w-100"
        ></el-date-picker>
      </el-form-item>

      <el-form-item
        :label="$t('State:')"
        prop="state"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-select
          v-model="form.state"
          placeholder="Select State"
          class="w-100"
          @change="getForms"
        >
          <el-option
            v-for="item in states"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="'Default Form'" prop="form">
        <v-select
          class="style-chooser"
          placeholder="Select a form"
          :options="forms"
          label="name"
          :reduce="(form) => form.id"
          taggable
          v-model="form.form"
        ></v-select>
      </el-form-item>

      <el-form-item :label="$t('First Date of Q1')" prop="fisrtDateQ1">
        <el-date-picker
          type="date"
          v-model="form.firstDateQ1"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
          class="w-100"
        ></el-date-picker>
      </el-form-item>

      <el-form-item
        :label="$t('Due Dates (Day)')"
        prop="dueDay"
        :rules="[{ required: true, message: 'Due day is required' }]"
      >
        <el-select
          v-model="form.dueDay"
          placeholder="Select Due Day"
          class="w-100"
        >
          <el-option v-for="day in days" :key="day" :label="day" :value="day">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="'Frequency'" prop="paymentplan">
        <v-select
          class="style-chooser"
          placeholder="Select a payment plan"
          :options="paymentplans"
          label="name"
          :reduce="(paymentplan) => paymentplan.id"
          v-model="form.paymentplan"
        ></v-select>
      </el-form-item>

      <el-form-item :label="$t('Assign to')" prop="assignto">
        <el-select
          v-model="form.assignto"
          placeholder="Select User"
          class="w-100"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('Status')"
        prop="active"
        :rules="[{ required: true, message: 'Status is required' }]"
      >
        <el-select
          v-model="form.active"
          placeholder="Select Status"
          class="w-100"
        >
          <el-option
            v-for="item in status_select"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save Register</el-button
      >
    </el-form>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import clientSaleTax from "@/services/api/clientSaleTax";
import user from "@/services/api/user";
import form from "@/services/api/form";
import paymentPlan from "@/services/api/paymentPlan";
import bank from "@/services/api/bank";
import stateSalesTax from "@/services/api/stateSalesTax";

export default {
  props: ["data"],
  data() {
    return {
      status_select: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      offices: [],
      clients: [],
      bankAccount: null,
      bankAccounts: [],
      users: [],
      states: [],
      forms: [],
      paymentplans: [],
      form: {
        id: null,
        office: null,
        corporate: null,
        clientName: null,
        bank: null,
        startdate: null,
        state: null,
        form: null,
        firstDateQ1: null,
        dueDay: null,
        paymentplan: null,
        assignto: null,
        account: null,
        active: null,
      },
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        this.discriminator = "office";
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }
    paymentPlan.get().then((response) => {
      this.paymentplans = response;
    });
    stateSalesTax.get().then((response) => {
      this.states = response;
    });
    //
    if (this.data) {
      console.log(this.data);
      this.form.id = this.data.id;
      this.form.office = this.data.corporate.officeId;
      this.form.corporate = this.data.corporate.uuid;
      corporate
        .getClientsByOffice(this.data.corporate.officeId)
        .then((response) => {
          this.clients = response;
        })
        .then(() => {
          this.getBankAccount();
          this.form.account = this.data.bankAccount;
          this.form.bank = this.data.bankId;
          this.setBankAccount(this.data.bankAccount)
        });
      this.form.startdate = this.data.startDate;
      this.form.state = this.data.form ? this.data.form.stateId : null;
      this.getForms();
      this.form.form = this.data.formId;
      this.form.firstDateQ1 = this.data.firstDateQ1;
      this.form.dueDay = this.data.dueDay;
      this.form.paymentplan = this.data.paymentPlanId;
      this.form.actve = this.data.active;
      user.getUsersByOffice(this.data.corporate.officeId).then((response) => {
        this.users = response;
      });
      this.form.assignto = this.data.responsible.id;
      this.form.active = this.data.active;
    }
  },
  computed: {
    days() {
      const days = [...Array(32).keys()].slice(1);
      console.log(days);
      return days;
    },
  },
  methods: {
    getBankAccount() {
      this.clients.forEach((client) => {
        if (
          client.credential_banks.length > 0 &&
          client.uuid === this.form.corporate
        ) {
          this.bankAccounts = client.credential_banks.map((bankAccount) => {
            let bankAccountX =
              bankAccount.account.length > 4
                ? "x".repeat(bankAccount.account.length - 4) +
                  bankAccount.account.substr(bankAccount.account.length - 4)
                : bankAccount.account;
            let bankName = bankAccount.bank.name + " - " + bankAccountX;
            return {
              account: bankAccount.account,
              bankId: bankAccount.bankId,
              name: bankName,
            };
          });
        }
      });
    },
    setBankAccount(bankAccount = null) {
      console.log(bankAccount)
      if(bankAccount === null){
        if (this.bankAccount) {
          this.form.account = this.bankAccount.account;
          this.form.bank = this.bankAccount.bankId;
        } else {
          this.form.account = null;
          this.form.bank = null;
        }
      }else{
        this.bankAccounts.forEach((bnkAccount) => {
          if(bnkAccount.account == bankAccount) {
            this.bankAccount = bnkAccount
          }
        })
      }
    },
    getClientsAndUsers() {
      corporate.getClientsByOffice(this.form.office).then((response) => {
        this.clients = response;
      });

      user.getUsersByOffice(this.form.office).then((response) => {
        this.users = response;
      });
    },
    getForms() {
      form.getByState(this.form.state).then((response) => {
        this.forms = response;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          clientSaleTax
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          clientSaleTax
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
